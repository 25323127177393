import React, { useContext } from 'react'
import { GlobalDataContext } from '../../context/context'
import { Link } from 'react-router-dom'
import { ButtonContent } from "../global/boton/ButtonContent";
import Navmenu from './NavMenu';


const Header = () => {
    const { rpdata } = useContext(GlobalDataContext);

    return (
        <div>

            <div className="bg-black p-2 text-center">
                <div className="md:w-1/2 mx-auto md:flex justify-between items-center text-center">
                    <ul className="flex justify-center space-x-4 pb-2 md:pb-0">
                        <li className="text-white md:text-[17px] text-[13px] hidden md:block ">
                            <span className="border-r-[1px] border-white pr-3">

                                {rpdata?.dbPrincipal?.location[0]?.address}
                            </span>
                        </li>
                        <li className="text-white md:text-[17px] text-[13px] ">
                            <span className="border-r-[1px] border-white pr-3">

                                {rpdata?.dbPrincipal?.workdays[0]?.day}
                            </span>
                        </li>
                        <li className="text-white md:text-[17px] text-[13px] ">
                            <span className="pr-3">

                                {rpdata?.dbPrincipal?.workHours[0]?.hour}
                            </span>
                        </li>
                    </ul>
                    <ul className="flex justify-center space-x-7">
                        {rpdata?.dbSocialMedia?.redes.map((item, index) => {
                            return (
                                <li key={index} className="text-white">
                                    <a href={item.url} target="_blank" rel='noopener noreferrer'>
                                        <i
                                            className={`fab fa-${item.icon}`}
                                            aria-hidden="true"
                                        />
                                    </a>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
            <div className='absolute right-[5%] left-[5%] z-50 pt-3  flex justify-between items-center'>
                {/* logo */}
                <div className='w-[80%] md:w-[20%] pr-3 md:pr-0 justify-center'>
                    <Link to={'/'}>
                        <img
                            src={`${rpdata?.dbPrincipal?.logo}`}
                            alt='no found'
                            className='w-[90%] bg-[#FF8000] md:-mt-20 md:pt-[28px] rounded-lg'
                        />
                    </Link>
                </div>
                {/* nav menu */}
                <nav className='w-[20%] md:w-[60%] text-center'>
                    <div className='relative'>
                        <Navmenu />
                    </div>
                </nav>
                <div className='hidden md:block md:w-[20%] '>
                <ButtonContent  />
                </div>
            </div>
        </div>
    )
}

export default Header